import { FieldMetaProps } from 'formik'
import { get, set } from 'lodash'
import { DropdownOption, Option } from 'src/components/Forms'

function notEmpty<TValue> (value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

interface LookupData {
  _id: string
  name?: string | null
  [key: string]: any
}

const createCheckboxOptions = (data: LookupData[], name: string): Option[] => {
  return data.map(d => {
    if (d._id !== undefined && d._id !== null && typeof d.name === 'string') {
      return { id: d._id, value: d._id, label: d.name, name }
    }
    return null
  }).filter(notEmpty)
}

interface CreateDropdownOptions {
  valueKey?: string
  labelKey?: string
}

const createDropdownOptions = (data: LookupData[], options?: CreateDropdownOptions): DropdownOption[] => {
  const { valueKey = '_id', labelKey = 'name' } = options ?? {}
  return data.map(d => {
    const value = get(d, valueKey)
    const label = get(d, labelKey)
    if (value !== undefined && value !== null && typeof label === 'string') {
      return { value, label }
    }
    return null
  }).filter(notEmpty)
}

function emptyStringToNull<V extends object = {}> (value: V, key: string): V
function emptyStringToNull<V extends string = ''> (value: V): null | string

function emptyStringToNull (value: object | string, key?: string): object | null | string {
  if (typeof value === 'object') {
    if (key === undefined) {
      return value
    }
    const objValue = get(value, key)
    if (objValue === '') {
      set(value, key, null)
    }
    return value
  } else if (typeof value === 'string') {
    if (value === '') {
      return null
    }
    return value
  }
  return value
}

interface FieldErrorData {
  error?: string
  hasError: boolean
}

const getFieldError = (meta: FieldMetaProps<any>): FieldErrorData => {
  const { error, touched } = meta
  return {
    error,
    hasError: error !== undefined && touched
  }
}

export {
  notEmpty,
  createCheckboxOptions,
  createDropdownOptions,
  emptyStringToNull,
  getFieldError
}
